<template>
  <div class="container">
    <form>
      <h2 class="text-center text-blue">Edit Service</h2>
      <small v-if="error" class="text-danger">{{ error }}</small>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Service Name"
          v-model="name"
        />
        <small v-if="nameError" class="text-danger">{{ nameError }}</small>
      </div>
      <br />
      <div class="form-group">
        <textarea
          maxlength="280"
          class="form-control"
          rows="4"
          placeholder="Service Summary"
          v-model="summary"
        ></textarea>
        <small v-if="summaryError" class="text-danger">{{
          summaryError
        }}</small>
      </div>
      <br />
      <div class="form-group">
        <textarea
          class="form-control"
          rows="10"
          placeholder="Service Details"
          v-model="details"
        ></textarea>
        <small v-if="detailsError" class="text-danger">{{
          detailsError
        }}</small>
      </div>
      <br />
      <div class="text-center">
        <button
          class="btn"
          :class="disabled ? 'disabled' : ''"
          @click.prevent="updateService"
        >
          EDIT
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EditService",
  computed: {
    ...mapGetters(["getService"]),
  },
  data() {
    return {
      id: "",
      error: "",
      name: "",
      nameError: "",
      details: "",
      detailsError: "",
      summary: "",
      summaryError: "",
      disabled: false,
    };
  },
  methods: {
    async updateService() {
      this.error = "";
      this.nameError = "";
      this.detailsError = "";
      this.summaryError = "";
      this.disabled = true;

      let errors = 0;

      if (!this.name.length || !this.name.replace(/\s/g, "").length) {
        errors++;
        this.nameError = "Service Name can not be empty.";
      }

      if (
        !this.summary.length ||
        this.summary.length > 280 ||
        !this.summary.replace(/\s/g, "").length
      ) {
        errors++;
        this.summaryError =
          "Service Summary can not be empty or have more than 280 characters.";
      }

      if (!this.details || !this.details.replace(/\s/g, "").length) {
        errors++;
        this.detailsError = "Service details can not be empty.";
      }

      if (errors) {
        this.disabled = false;
        return;
      }

      const updatedService = {
        id: this.id,
        service: {
          name: this.name,
          summary: this.summary,
          details: this.details.replaceAll("\n", "<br/>"),
        },
      };

      const res = await this.$store.dispatch("updateService", updatedService);
      if (!res.isValid) {
        this.error = "An error occured.";
        this.disabled = false;
        return;
      }

      this.error = "";
      this.name = "";
      this.nameError = "";
      this.details = "";
      this.detailsError = "";
      this.summary = "";
      this.summaryError = "";
      this.disabled = false;

      this.$store.commit("serviceStatus", null);
      this.$router.push("/services");
    },
  },
  mounted() {
    this.id = this.getService.id;
    this.name = this.getService.name;
    this.summary = this.getService.summary;
    this.details = this.getService.details.replaceAll("<br/>", "\n");
  },
};
</script>

<style scoped>
.btn {
  background-color: #023b95;
  color: white;
  width: 50%;
  border-radius: 30px;
  padding: 10px;
}
</style>
